




  import Vue from 'vue'
  import { Component, Prop } from 'nuxt-property-decorator'
  import { CMS } from '@one/types'

  @Component
  export default class OneCmsStaticComponent extends Vue {
    name: string = 'one-cms-static-component'

    @Prop({
      required: true,
      type: String,
    })
    readonly html!: string

    @Prop({
      required: false,
      type: Object,
    })
    readonly htmlBox!: CMS.ContentBox // backward compatibility
  }
